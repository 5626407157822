// import { Typography } from '@mui/material';
import React from 'react';
import './Privacy.css';

function Privacy() {
    const name = "Devplayground";
    const site = "devplayground.it";
    const site_short = "devplayground.it";
    const email = "info@devplayground.it";
    const owner = "Giuseppe Castelluzzo – P. IVA 03025160841 – Piazzetta Pitagora, 2 – 92100 Agrigento – Italy";
    const subject = "ricevere informazioni di contatto di chi è interessato ai servizi offerti dalla devplayground";
    const objectif = `
        Consentire agli utenti la fruizione dei servizi del sito offrendo la migliore esperienza di esplorazione, al contempo, una ricerca facile,
        rapida e mirata di informazioni, aziende e/o professionisti che propongono all'interno del sito vari servizi o prodotti d'interesse turistico
        facilitando le condizioni per vivere un esperienza di vacanza completa e soddisfacente.
    `;
    
    return (
        <div className="Privacy">
            {/* <Typography variant="h6" component="h2">
                Text in a modal
            </Typography>
            <Typography sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography> */}
            {/* <h2>Informativa sulla privacy</h2> */}

            <h3>INFORMATIVA PRIVACY AI SENSI&nbsp;DELL'ARTICOLO 13&nbsp;DEL REGOLAMENTO N. 2016/679/UE&nbsp;(General Data Protection Regulation – “GDPR”)</h3>
            <p>
                Gentile Interessato, desideriamo informarla che in ottemperanza degli obblighi derivanti dalla normativa comunitaria,
                (Regolamento europeo per la protezione dei dati personali n. 679/2016, GDPR) e nazionale (D. Lgs 30 giugno 2003 n. 196,
                Codice in materia di protezione dei dati personali) e successive modifiche,
                il presente sito {site} rispetta e tutela la riservatezza dei visitatori e degli utenti, ponendo in essere ogni sforzo possibile
                e proporzionato per non ledere i diritti degli utenti.
            </p>
            
            <p>
                La presente privacy policy si applica esclusivamente alle attività online di {site} ed è valida per tutti i visitatori/utenti del sito
                a prescindere dal supporto utilizzato, ovvero computer o altro dispositivo utile a connettersi al sito stesso.
                Non si applica alle informazioni raccolte tramite canali diversi dal presente sito web. Lo scopo dell'informativa privacy è di fornire
                la massima trasparenza relativamente alle informazioni che il sito raccoglie e come le usa.
            </p>
            
            <p>
                In generale, tutti i dati sono raccolti e trattati al fine ultimo di assolvere al completo funzionamento dei servizi del sito {site}
                e di conseguire gli obiettivi cui è destinato: {subject}.
            </p>

            <p>
                Ciò premesso, il trattamento dei Suoi dati ha come base giuridica il suo consenso ed è effettuato per la seguente finalità:
            </p>

            <ol><li>FINALITA' DEL TRATTAMENTO</li></ol>
            <p>
                {objectif}
            </p>
            
            <ol start="2"><li>CATEGORIE DEI DATI TRATTATI</li></ol>
            <div className="subtitle">2.1 Dati raccolti in automatico</div>
            <p>
                
                Il Sito {site} è liberamente navigabile senza dover fornire alcun dato personale. Tuttavia, i sistemi informatici e i software preposti
                al funzionamento dello stesso acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita
                nell'uso dei protocolli di comunicazione di Internet.
                
                In questa categoria rientrano gli indirizzi IP dei computer utilizzati dagli utenti che si connettono al portale, la url delle risorse richieste,
                l'orario della richiesta, la dimensione del file ottenuto in risposta, parametri riguardanti il sistema operativo, il browser e l'ambiente informatico
                utilizzato dall'utente etc.
                
                Questi dati vengono utilizzati soltanto per ricavare informazioni statistiche sull'utilizzo del sito e per controllarne il corretto funzionamento.
            </p>

            <div className="subtitle">2.2 Dati forniti volontariamente dall'utente</div>
            <p>
                L'invio volontario ed esplicito di posta elettronica agli indirizzi indicati nei differenti canali di accesso di questo sito non comporta richiesta di consenso
                e l'eventuale compilazione di moduli di contatto/form specificamente predisposti comportano la successiva acquisizione dell'indirizzo e dei dati del mittente/utente,
                necessari per rispondere alle istanze prodotte e/o erogare il servizio richiesto.
                
                L'invio volontario, da parte Sua, di mail ai nostri indirizzi di posta elettronica non necessitano di ulteriori informative o richieste di consenso.
                
                Invece specifiche informative di sintesi saranno riportate o visualizzate nelle pagine del sito predisposte per particolari servizi a richiesta
                (ad es. moduli di contatto/form).
            </p>

            <p>
                In definitiva, il conferimento di dati personali è facoltativo.
                Tuttavia esso è richiesto per l'accesso ad alcune funzionalità come il modulo di contatto all'interno del Sito.

                L'utente dovrà pertanto acconsentire esplicitamente all'utilizzo dei dati riportati in questi moduli accettando la privacy policy del Sito.
                L'eventuale rifiuto, anche parziale, di conferire tali dati, indicati espressamente come necessari, comporterà l'impossibilità di usufruire
                di alcuni strumenti del portale, lasciando disponibili tutte le altre funzionalità.
            </p>
            
            <p>
                Al fine di evitare inconvenienti che possono derivare dal fatto che {site} disponga di dati non corretti/non aggiornati,
                sia l'utente-viaggiatore sia le aziende e/o i professionisti che promuovono servizi o prodotti mediante il sito {site} garantiscono che tutta
                l'informazione personale e/o relativa alla propria attività presentata è totalmente attendibile ed aggiornata essendo gli unici responsabili
                dell'inesattezza o della non veridicità dei dati volontariamente forniti e dei danni eventualmente arrecati a {site} o a terzi attraverso
                l'utilizzo dei servizi offerti dal potale. Al fine di mantenere esatti, aggiornati, pertinenti e completi i dati forniti,
                invitiamo tutti gli utenti e le aziende presenti nel sito a segnalarci ogni modifica intervenuta inviando una mail a {email}.

                I dati ricavati sono quelli adeguati, pertinenti e non eccessivi riguardo all'ambito, le finalità e i servizi indicati, espliciti e legittimi di {site}.
            </p>
            
            <ol start="3"><li>TITOLARE E RESPONSABILE DEL TRATTAMENTO</li></ol>
            <p>
                Il titolare e responsabile del trattamento dei dati raccolti tramite questo sito è {owner}.
                Quando nella presente informativa si menziona {site}, “{name}” o “{site_short}” ci riferisce al titolare e responsabile del trattamento.
            </p>
            
            <ol start="4"><li>MODALITA' DI TRATTAMENTO</li></ol>
            <p>
                I dati personali da Lei forniti verranno trattati con strumenti informatici nel rispetto delle misure adeguate di sicurezza.
                Sarà garantita la sicurezza logica e fisica dei dati e, in generale, la riservatezza dei dati personali trattati,
                mettendo in atto tutte le necessarie misure tecniche e organizzative adeguate a garantire la loro sicurezza.
                I trattamenti sui dati personali sono eseguiti con modalità prevalentemente elettronica e telematica da {site} e da altri soggetti che,
                opportunamente selezionati in base ad affidabilità e competenza, svolgono operazioni strettamente connesse alle finalità summenzionate
                del sito web e ai suoi servizi: gestire i vari tipi di richiesta, incluse quelle d'acquisto di prodotti e servizi effettuate tramite il sito web.
            </p>
            
            <ol start="5"><li>DESTINATARI DEI DATI</li></ol>
            <p>
                I dati personali saranno comunicati ai soggetti terzi coinvolti nelle attività necessarie per le finalità connesse all'uso del sito web
                e ai suoi servizi ovvero consulenti e partner di {name}.
            </p>
            
            <ol start="6"><li>PERIODO DI CONSERVAZIONE DEI DATI</li></ol>
            <p>
                I dati raccolti verranno conservati per un arco di tempo non superiore al conseguimento delle finalità per le quali sono trattati
                e in base alle scadenze previste dalle norme di legge.
            </p>
            
            <ol start="7"><li>LINK AD ALTRI SITI WEB</li></ol>
            <p>
                {site_short} contiene link ad altri siti web. Se l'utente accede ad altri siti tramite i link forniti sul Sito, si consiglia di prendere visione
                delle normative sulla privacy pubblicate su tali siti al fine di comprenderne le procedure di raccolta e trattamento dei dati personali
                che potrebbero differire dalla nostre. {site_short} infatti, non controlla nè compie operazioni di monitoraggio di siti web di terze parti,
                non è responsabile dei contenuti di tali siti web, né delle regole da essi adottate.
            </p>
            
            <ol start="8"><li>MISURE DI SICUREZZA</li></ol>
            <p>
                {site_short} si impegna a trattare i dati degli utenti in maniera lecita e corretta, adottando le opportune misure di sicurezza volte ad impedire
                accessi non autorizzati, divulgazione, modifica o distruzione non autorizzata dei dati.
                Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate.
            </p>
            
            <ol start="9"><li>DIRITTI DELL'INTERESSATO</li></ol>
            <p>
                In ogni momento l'Interessato potrà esercitare i diritti di seguito indicati:
                il diritto a richiedere al Titolare l'accesso ai Suoi dati, la rettifica o la cancellazione degli stessi, la limitazione del trattamento
                o la possibilità di opporsi al trattamento, di richiedere la portabilità dei dati, di proporre reclamo all'Autorità di controllo.
                L'interessato potrà esercitare tali diritti tramite semplice comunicazione al Titolare all'indirizzo e-mail: {email}.
            </p>
            
            <ol start="10"><li>MODIFICHE E AGGIORNAMENTI DELLA PRIVACY POLICY</li></ol>
            <p>
                {name} potrà modificare o semplicemente aggiornare, in tutto o in parte, la privacy policy del sito, anche in ragione della modifica
                delle norme che disciplinano questa materia.
                Non appena qualsiasi modifica o aggiornamento alla privacy policy sarà effettuata essa sarà disponibile per tutti gli utenti nella apposita sezione
                privacy del sito e saranno vincolanti non appena pubblicati.
                Pertanto, per essere sempre aggiornato sulla normativa, le consigliamo di accedere con regolarità a questa sezione.
            </p>
            
            <p className="last-update">
                Ultimo aggiornamento: 05/07/2022
            </p>
        </div>
    )
}

export default Privacy;