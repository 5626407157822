// import * as React from 'react';
// import { styled, createTheme, ThemeProvider } from '@mui/system';
import { Button as MuiButton } from '@mui/material';

// import { styled } from '@mui/system';

const Button = (props) => (
    <MuiButton variant="contained" sx={{
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "8px",
        paddingBottom: "8px"
    }} {...props}>
        {props.children}
    </MuiButton>
);

export default Button;

