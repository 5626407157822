import * as React from 'react';
import { ReactComponent as LogoSvg } from '../logo.svg';

import './Logo.css';

function Logo() {

    return (
        <div className="Logo">
          <LogoSvg />
        </div>
    );
}

export const animateLogo = () => {
    const letters = document.querySelectorAll('.Logo svg .letters > *');

    // console.debug(letters);

    letters.forEach((letter) => {
      letter.style.transform = 'none';
    })
};

export default Logo;