import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
// import ReactGA from 'react-ga4';
import ReCAPTCHA from 'react-google-recaptcha';
import { Alert, Box, Checkbox, FormControlLabel, FormHelperText } from '@mui/material';

import { useSwiper } from 'swiper/react';
import TextField from './TextField';
import Button from './Button';

const initialData = {
    name: '',
    email: '',
    body: '',
    "g-recaptcha-response": '',
    privacy: false
};
// const enableCaptcha = window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1";
const enableCaptcha = true;

function Form(props) {
    const swiper = useSwiper();

    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState({});
    const [data, setData] = useState(initialData);

    console.log('data', data);

    // TODO:
    // Send a custom event
    // ReactGA.event({
    //     category: "your category",
    //     action: "your action",
    //     label: "your label", // optional
    //     value: 99, // optional, must be a number
    //     nonInteraction: true, // optional, true/false
    //     transport: "xhr", // optional, beacon/xhr/image
    // });

    const handleAbort = () => {
        setData(initialData);
        setErrors({});
        swiper.slideTo(0);
    }

    const handleSubmit = async () => {
        const errors = {};

        if(!data.name) {
            errors.name = true;
        }

        if(!data.email) {
            errors.email = true;
        } else {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if ( !re.test(data.email) ) {
                errors.email = "email non valida";
            }
        }

        if(!data.body) {
            errors.body = true;
        }

        if(!data.privacy) {
            errors.privacy = true;
        }

        if(enableCaptcha && !data["g-recaptcha-response"]) {
            errors["g-recaptcha-response"] = true;
        }

        setErrors(errors);

        if(Object.keys(errors).length > 0) {
            console.log('an error(s) occurs', errors);
            return;
        }

        // DEBUG
        // console.debug(errors);
        // return;

        if(props.beforeSubmit && props.beforeSubmit instanceof Function) {
            props.beforeSubmit();
        }

        try {
            if(enableCaptcha) {
                const response = await emailjs.send('service_9izpv9v', 'template_ph900gv', data, 'g3HUS_rlJ5mZjZgSX')
                console.info(response);
            } else {
                await (function wait(ms) {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                        console.log("Done waiting");
                        resolve(ms)
                        }, ms )
                    })
                })(2000);
            }
            console.log('form submitted');
        } catch(e) {
            console.error(e);
            errors.generic = "Si è verificato un errore";
            setErrors(errors);
            return;
        } finally {
            if(props.afterSubmit && props.afterSubmit instanceof Function) {
                props.afterSubmit();
            }
        }

        setSubmitted(true);
        setData(initialData);
    }

    const goHome = () => {
        swiper.slideTo(0);
        setTimeout(() => {
            setSubmitted(false);
        }, 500);
    }

    return (
        <div className="Form" style={{paddingLeft: 15, paddingRight: 15}}>
            {
                !submitted
                ?
                <Box
                    component="form"
                    // sx={{
                    //     '& .MuiTextField-root': { m: 1, width: '25ch' },
                    // }}
                    noValidate
                    autoComplete="off"
                >
                    {
                        errors.generic
                        ?
                        <Alert severity="error" style={{ marginBottom: 25 }} onClose={() => setErrors({...errors, generic: ''})}>{errors.generic}</Alert>
                        :
                        null
                    }
                    <div>
                        <TextField label="Nome*" value={data.name} error={!!errors.name} helperText={errors.name ? ( typeof errors.name  === 'string' ? errors.name : "campo obbligatorio" ) : []} onChange={ (e) => setData({ ...data, name: e.target.value }) }/>
                    </div>

                    <div>
                        <TextField label="Email*" value={data.email} error={!!errors.email} helperText={errors.email ? ( typeof errors.email === 'string' ? errors.email : "campo obbligatorio" ) : []} onChange={ (e) => setData({ ...data, email: e.target.value }) }/>
                    </div>

                    {/* <div>
                        <TextField label="Telefono" value={data.phone} onChange={ (e) => setData({ ...data, phone: e.target.value }) } />
                    </div> */}

                    <div>
                        <TextField
                            label="Testo*"
                            value={data.body}
                            error={ !!errors.body }
                            helperText={ errors.body ? "campo obbligatorio" : "Inserire il corpo del messaggio" }
                            multiline
                            rows={6}
                            onChange={ (e) => setData({ ...data, body: e.target.value }) }
                        />
                    </div>

                    <div style={{ display: 'inline-block', marginTop: 0 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size='small'
                                    sx={{
                                        color: "#7e7e7e",
                                        // '&.Mui-checked': {
                                        //   color: pink[600],
                                        // },
                                    }}
                                    checked={data.privacy}
                                    onChange={  (e) => setData({ ...data, "privacy": e.target.checked }) }
                                />
                            }
                            label="accetto il trattamento dei dati personali"
                            error="ciao"
                        />
                        {
                            errors["privacy"]
                            ?
                            <FormHelperText error style={{ position: 'absolute', marginTop: -10 }}>E' necessario accettare la privacy policy</FormHelperText>
                            :
                            null
                        }
                        
                        {
                            enableCaptcha
                            ?
                            <ReCAPTCHA
                                sitekey="6LexjL4gAAAAAEtLe0VGQ9E1dlOvMS-4PN3-43Xz"
                                onChange={  (token) => setData({ ...data, "g-recaptcha-response": token}) }
                            />
                            :
                            null
                        }
                        {
                            enableCaptcha && errors["g-recaptcha-response"]
                            ?
                            <FormHelperText error style={{ position: 'absolute', marginTop: -10 }}>completare il ReCAPTCHA</FormHelperText>
                            :
                            null
                        }
                    </div>

                    <div style={{ marginTop: 10 }}>
                        <Button color="error" onClick={ handleAbort }>Annulla</Button>
                        &nbsp;
                        <Button onClick={ handleSubmit }>Invia</Button>
                    </div>
                </Box>
                :
                <>
                    <p style={{marginBottom: 45}}>
                        Vi ringraziamo per averci contattato. Vi risponderemo non appena possibile.
                    </p>

                    <Button onClick={ goHome }>Home</Button>
                </>
            }
        </div>
    );
}

export default Form;