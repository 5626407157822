import * as React from 'react';
import { useSwiper } from 'swiper/react';
import Button from './Button';

import './Slogan.css'

function Slogan(props) {
    const swiper = useSwiper();

    return (
        <div {...props} className="Slogan">
            <p className="experience-text">
                Abbiamo maturato un'esperienza ventennale nel settore digital in svariati ambiti commerciali.<br/>
                Curiamo meticolosamente i dettagli e realizziamo soluzioni uniche in grado di esprimere le peculiarità di un brand.
            </p>
            
            <p className="quality-text">
                Offriamo servizi di alta qualità attraverso il nostro team composto da competenze complementari che unite raggiungono la completezza necessaria nel settore informatico: software gestionali, automazione, app mobile, video streaming, siti web moderni, produzioni grafiche, ottimizzazioni SEO.
            </p>

            <p className="technology-text">
                Utilizziamo le migliori e più moderne tecnologie a supporto dello sviluppo delle vostre idee: AWS, React, Flutter, Laravel, NodeJS, WebSocket.
            </p>

            <p className="contacts-us-text">
                Inviaci un messaggio per farci conoscere le tue necessità utilizzando l'apposito modulo che puoi raggiungere cliccando sul tasto "Contattaci", risponderemo in tempi brevissimi.
            </p>

            <Button variant="contained" onClick={ () => swiper.slideTo(1) }>Contattaci</Button>
        </div>
    );
}

export default Slogan;