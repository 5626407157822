import React from 'react';
import './Cookie.css';

function Cookie() {
    const site = "devplayground.it";

    return (
        <div className="Cookie">
            <h3>Informativa sui cookie</h3>
            <p>
                I cookie fanno parte degli strumenti usati da {site} per assicurare una corretta navigabilità del sito.
                Il presente documento fornisce informazioni dettagliate sull'uso dei cookie e di tecnologie similari, su come sono utilizzati da {site} e su come gestirli.
            </p>
            
            <ol><li>COSA SONO I COOKIE</li></ol>
            <p>
                I Cookie sono costituiti da piccole porzioni di testo (lettere e/o numeri) installate nel browser e inviati dal sito web visitato al dispositivo
                utilizzato dall’Utente (computer, tablet, smartphone).
                Lo scopo dei cookie è garantire la migliore esperienza possibile all'Utente quando visita più volte uno stesso sito web.
                In particolare, i cookie consentono: di navigare in modo efficiente da una pagina all'altra del sito web; di memorizzare le preferenze inserite
                (ad es. preferenza della lingua o altre impostazioni online); consentono di evitare di inserire le stesse informazioni più volte durante la visita
                (come ad es. nome utente e password); misurano l’utilizzo dei servizi da parte degli Utenti per ottimizzare l’esperienza di navigazione e
                i servizi stessi; presentano contenuti e annunci promozionali coerenti con gli interessi e il comportamento manifestato dall’Utente durante la navigazione.
            </p>
            
            <p>
                In base alle finalità descritte alcuni cookie potrebbero necessitare del consenso dell'Utente.
                Quando l’installazione di cookie avviene sulla base del consenso, tale consenso può essere revocato liberamente in ogni momento seguendo le istruzioni
                contenute in questo documento.
            </p>
            
            <p>
                Alcune delle funzioni dei cookie possono essere demandate ad altre tecnologie. Nel presente documento con il termine ‘cookie’ si vuol
                far riferimento sia ai cookie, propriamente detti, sia a tutte le tecnologie similari (come, ad es. i web beacon).
            </p>
            
            <ol start="2"><li>TIPOLOGIA DI COOKIE E UTILIZZO NEL NOSTRO SITO WEB</li></ol>
            <p>
                Relativamente alla tipologia dei cookie, ne esistono di diversi tipi:
            </p>
            
            <div className="subtitle">2.1 Cookie essenziali</div>
            <p>
                Questi cookies aiutano gli Utenti ad accedere e a navigare all'interno del nostro sito e ad utilizzare tutti i suoi servizi essenziali.
                Essi non vengono utilizzati per scopi ulteriori e sono normalmente installati direttamente dal titolare o gestore del sito web.
                Senza questi cookies, il nostro sito non funzionerebbe correttamente e non potrebbe rendere disponibili alcune funzioni importanti.
                Per esempio, potremmo usare i cookies per mantenervi collegati durante la vostra visita, in modo che il sito non vi richieda di collegarvi
                più volte per accedere a pagine diverse.
                In particolare, utilizziamo i cookies essenziali anche per finalità di prevenzione e sicurezza.
            </p>
            
            <div className="subtitle">2.2 Cookie analitici e di personalizzazione</div>
            <p>
                Questi cookies servono a rendere l'esperienza degli Utenti su un sito web il più agevole e il più piacevole possibile.
                Utilizziamo questi cookies per aiutarci a capire come il nostro sito web viene utilizzato e come possiamo migliorare la Sua esperienza su di esso.
                I cookies possono fornirci informazioni anonime per aiutarci a capire come i visitatori raggiungono il nostro sito,
                quali parti del nostro sito web trovano interessanti (ad es. pagine visitate, tempo di permanenza) o se si verificano errori durante la navigazione.
                Queste informazioni sono utilizzate solo in forma anonima e aggregata. Utilizziamo i cookies anche per salvare le impostazioni e le preferenze
                effettuate sul nostro sito web, come la lingua scelta, le attrazioni turistiche preferite o altre opzioni previste da {site}.
                Alcuni cookies di personalizzazione sono essenziali se desidera utilizzare alcune funzionalità del sito.
            </p>
            
            <ol start="3"><li>ALTRE TIPOLOGIE DI COOKIE O STRUMENTI TERZI CHE POTREBBERO INSTALLARNE</li></ol>
            <p>
                I cookie possono essere di prima o di terza parte, dove per “prima parte” si intendono i cookie sviluppati dal Titolare del sito stesso,
                mentre per “terza parte” si intendono i cookie sviluppati da Terzi rispetto il Titolare del sito.
                In caso di cookie di terze parti, il sito non ha né può avere tecnicamente alcun controllo diretto dei singoli cookie.
                L'Utente può gestire però questi cookie attraverso le impostazioni del browser (come indicato più avanti), o consultando alcuni i siti indicati
                nella sezione “Gestione dei cookie”.
                Pertanto per dettagliate informazioni sui singoli cookie, sul trattamento che ne viene fatto e come gestire il consenso, l'Utente è invitato a verificare
                sul sito della “terza parte indicato” di seguito.
            </p>
            
            <div className="subtitle">3.1 Cookie utilizzati per consentire analisi statistiche.</div>
            <p>
                Questo tipo di cookie permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento
                dell'Utente a fini statistici.
            </p>
            
            <p>
                <strong>Google Analytics.</strong> Si tratta di cookie di monitoraggio per la generazione di statistiche sull'utilizzo del sito.
                Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”).
                Google Analytics utilizza i “cookie” per raccogliere e analizzare in forma anonima le informazioni sui comportamenti di utilizzo del sito {site}
                allo scopo di redigere report sulle attività svolte all'interno del sito stesso.
                Google può anche comunicare queste informazioni a terzi ove ciò sia imposto dalla legge o laddove tali terzi trattino le suddette informazioni per conto di Google.
                Per maggiori informazioni o gestire il consenso, si rinvia ai link di seguito indicati:&nbsp;
                <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.google.com/intl/it/policies/privacy/">Privacy Policy</a>
                –
                <a target="_blank" rel="noopener noreferrer nofollow" href="https://tools.google.com/dlpage/gaoptout?hl=it">Opt Out</a>
            </p>
            
            <p>
                <strong>Google Tag Manager.</strong> Google Tag Manager consente di implementare Google Analytics utilizzando il tag di Universal Analytics
                o la versione classica del tag.
                Google Tag Manager rientra quindi nel servizio di statistica fornito sempre da Google Inc. (“Google”). Per maggiori informazioni, si rinvia ai link
                di seguito indicato:&nbsp;
                <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.google.com/intl/policies/privacy/">Privacy Policy</a>
            </p>
            
            <p>
                <strong>Monitoraggio conversioni di Google Adwords (Google Ads).</strong>
                Il monitoraggio conversioni di Google Ads è un servizio di statistiche fornito da Google LLC che collega i dati di utilizzo provenienti dal network
                di annunci Google Ads con le azioni compiute all'interno di questo sito web.
                Per maggiori informazioni, si rinvia ai link di seguito indicato:&nbsp;
                <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.google.com/intl/it/policies/privacy/">Privacy Policy</a>
            </p>
            
            <div className="subtitle">3.2 Cookie utilizzati per consentire analisi di marketing.</div>
            <p>
                Per monitorare il funzionamento e l’efficacia della promozione di {site} su internet utilizziamo Google Ads Remarketing, un servizio annunci basato
                sulle precedenti visite effettuate sul nostro sito web e sugli interessi dell'utente.
                Google Ads Remarketing (Google LLC) è un servizio di remarketing e behavioral targeting fornito da Google LLC che collega l’attività di {site}
                con il network di advertising Google Ads ed il Cookie DoubleClick.
                Per maggiori informazioni o gestire il consenso, si rinvia ai link di seguito indicati:&nbsp;
                <a target="_blank" rel="noopener noreferrer nofollow" href="http://www.google.com/intl/it/policies/privacy/">Privacy Policy</a>
                –
                <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.google.com/settings/ads/onweb/optout">Opt Out</a>
            </p>
            
            <div className="subtitle">3.3 Cookie utilizzati per consentire le funzionalità dei social media.</div>
            <p>
                Questo tipo di cookie consentono agli utenti di condividere i contenuti di {site} attraverso i social network preferiti.
                Questi cookie sono installati da siti web di “terze parti” che offrono le loro funzionalità mediante i plugin e/o bottoni ospitati nelle pagine.
            </p>
            
            <p>
                <strong>Facebook</strong> (<a target="_blank" rel="noopener noreferrer nofollow" href="https://www.facebook.com/help/cookies/">link informativa cookie</a>)
            </p>
            
            <p>
                <strong>Twitter</strong> (<a target="_blank" rel="noopener noreferrer nofollow" href="https://help.twitter.com/it/rules-and-policies/twitter-cookies">link informativa cookie</a>)
            </p>
            
            <p>
                <strong>WhatsApp</strong> (<a target="_blank" rel="noopener noreferrer nofollow" href="https://www.whatsapp.com/legal/#key-updates">link informativa cookie</a>)
            </p>
            
            <ol start="4"><li>DURATA DEI COOKIE</li></ol>
            <p>
                Alcuni cookie (cookie di sessione) restano attivi solo fino alla chiusura del browser o all'esecuzione del comando di logout.
                Altri cookie “sopravvivono” alla chiusura del browser e sono disponibili anche in successive visite dell’utente.
                Questi cookie sono detti persistenti e la loro durata è fissata dal server al momento della loro creazione.
                In alcuni casi è fissata una scadenza, in altri casi la durata è illimitata. I sistemi informatici utilizzati per {site} utilizzano
                sia cookie temporanei sia cookie permanenti. Navigando sulle pagine di {site} si può interagire con siti gestiti da terze parti
                che possono creare o modificare cookie permanenti e di profilazione marketing.
            </p>
            
            <ol start="5"><li>GESTIONE DEI COOKIE</li></ol>
            <p>
                In aggiunta a quanto indicato in questo documento, l'Utente può gestire le preferenze relative ai Cookie direttamente all’interno
                del proprio browser ed impedire, ad esempio, che terze parti possano installarne.
                Tramite le preferenze del browser è inoltre possibile eliminare i Cookie installati in passato, incluso il Cookie in cui venga eventualmente
                salvato il consenso all'installazione di Cookie da parte di questo sito.
                L'Utente può trovare informazioni su come gestire i Cookie con alcuni dei browser più diffusi ad esempio ai seguenti indirizzi:&nbsp;
                <a target="_blank" rel="noopener noreferrer nofollow" href="https://support.google.com/chrome/answer/95647?hl=it&amp;p=cpn_cookies">Google Chrome</a>,
                <a target="_blank" rel="noopener noreferrer nofollow" href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">Mozilla Firefox</a>,
                <a target="_blank" rel="noopener noreferrer nofollow" href="https://support.apple.com/kb/PH21411">Apple Safari</a> e
                <a target="_blank" rel="noopener noreferrer nofollow" href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies">Microsoft Internet Explorer</a>.
            </p>
            
            <p>
                Come già precedentemente detto, l'installazione di Cookie e di altri sistemi di tracciamento operata da terze parti tramite i servizi
                utilizzati all'interno del nostro sito web non può essere tecnicamente controllata dal Titolare.
                Pertanto, ogni riferimento specifico a Cookie e sistemi di tracciamento installati da terze parti è da considerarsi indicativo.
                Per ottenere informazioni complete, l'Utente è invitato a consultare la privacy policy degli eventuali servizi terzi elencati in questo documento.
            </p>
            
            <p>
                Con riferimento a Cookie installati da terze parti, ricordiamo che l'Utente può gestire le proprie impostazioni e revocare il consenso
                visitando il relativo link di opt out (qualora disponibile),&nbsp; utilizzando gli strumenti descritti nella privacy policy della terza parte
                o contattando direttamente la stessa.
                Inoltre, è utile evidenziare che la maggior parte dei network pubblicitari offrono agli utenti il modo per disabilitare i cookies pubblicitari.
                Pertanto, oltre alle informazioni fornite qui, vi invitiamo a consultare siti web come&nbsp;
                <a target="_blank" rel="noopener noreferrer nofollow" href="http://www.aboutads.info/choices/">www.aboutads.info/choices/</a>,&nbsp;
                <a target="_blank" rel="noopener noreferrer nofollow" href="http://www.youronlinechoices.com/">www.youronlinechoices.com</a>,&nbsp;
                <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.networkadvertising.org/understanding-digital-advertising">Network Advertising Initiative</a>,&nbsp;
                <a target="_blank" rel="noopener noreferrer nofollow" href="https://youradchoices.ca/understanding-online-advertising/">DAAC</a>,&nbsp;
                <a target="_blank" rel="noopener noreferrer nofollow" href="http://www.ddai.info/optout">DDAI</a> o altri servizi analoghi.
            </p>
            
            <ol start="6"><li>ACCETTAZIONE E RINUNCIA DEI COOKIE</li></ol>
            <p>Proseguendo nella navigazione su questo sito, chiudendo la fascetta informativa o facendo click in una qualsiasi parte della pagina o scorrendola per evidenziare ulteriore contenuto, si accetta la Cookie Policy e verranno impostati e raccolti i cookie. In caso di mancata accettazione dei cookie mediante abbandono della navigazione, eventuali cookie già registrati localmente nel Suo browser rimarranno ivi registrati ma non saranno più letti né utilizzati da noi fino ad una successiva ed eventuale accettazione della Policy. <strong>Lei avrà sempre la possibilità di rimuovere tali cookie in qualsiasi momento attraverso le modalità citate</strong>.</p>

            <p className="last-update">
                Ultimo aggiornamento: 05/07/2022
            </p>
        </div>
    )
}

export default Cookie;