// import * as React from 'react';
// import { styled, createTheme, ThemeProvider } from '@mui/system';
import { TextField as MuiTextField } from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 360,
        paddingBottom: "25px!important",
        maxWidth: "calc(100%)",
        //   height: 40,

        "& .MuiFilledInput-root": {
            backgroundColor: "rgba(255,255,255,0.20)"
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "rgba(255,255,255,0.40)"
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "rgba(255,255,255,0.60)"
        },
        "& .MuiFormHelperText-root": {
            position: "absolute",
            bottom: 5,
            color: "rgba(255,255,255,0.40)"
        },
        "& .MuiFormHelperText-root.Mui-error": {
            color: "#d32f2f"
        },
        "& .MuiInputLabel-root": {
            fontSize: "1em",
            top: "2px"
        },
        "& .MuiFilledInput": {
            paddingTop: "20px"
        },
    }
}));

// import { styled } from '@mui/system';

const TextField = (props) => {
    const classes = useStyles();
    
    return (
        <MuiTextField className={classes.root} variant="filled" {...props}>
            {props.children}
        </MuiTextField>
    );
}

export default TextField;