import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Logo, { animateLogo } from './components/Logo';
import Loading from './components/Loading';
import Footer from './components/Footer';
import Swiper from './components/Swiper';

import './Main.css';

function Main() {
  const theme = createTheme({
    typography: {
      "fontFamily": `"PT Sans", sans-serif`,
      // "fontSize": 14,
      // "fontWeightLight": 300,
      // "fontWeightRegular": 400,
      // "fontWeightMedium": 500
    },
    shape: {
      borderRadius: 12,
    },
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#1D71B8',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      // secondary: {
        // light: '#0066ff',
        // main: '#0044ff',
        // dark: will be calculated from palette.secondary.main,
        // contrastText: '#ffcc00',
      // },
       // Provide every color token (light, main, dark, and contrastText) when using
       // custom colors for props in Material UI's components.
       // Then you will be able to use it like this: `<Button color="custom">`
       // (For TypeScript, you need to add module augmentation for the `custom` value)
      // custom: {
      //   light: '#ffa726',
      //   main: '#f57c00',
      //   dark: '#ef6c00',
      //   contrastText: 'rgba(0, 0, 0, 0.87)',
      // },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      // contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      // tonalOffset: 0.2,
    },
    overrides: {
      MuiFormControlLabel: {
        label: {
          fontSize: 11,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 11,
      },
    },
    components: {
      MuiFormControlLabel: {
        styleOverrides: {
          fontSize: 11,
          root: {
            fontSize: 11
          }
        }
      }
    }
  });

  const [loading, setLoading] = useState(true);

  const calculateContentHeight = (el) => {
    let height = 0;

    (Array.prototype.slice.call(el.children)).forEach((child) => {
      var styles = window.getComputedStyle(child);
      var margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
      // console.log(child, child.clientHeight, child.offsetHeight, margin);
      // height += child.clientHeight + margin;
      height += child.offsetHeight + margin;
    });

    // console.log(height);

    return height;
  }

  useEffect(() => {
    console.log('useEffect...');

    const init = () => {
      setTimeout(() => {
        console.log('init...');
        setLoading(false);
        setTimeout(() => {
          animateLogo();
          setTimeout(() => {
            const contentDiv = document.querySelector('.content');

            contentDiv.style.height = calculateContentHeight(contentDiv) + 'px';

            setTimeout(() => {
              contentDiv.style.height = 'auto';
            }, 1300);
          }, 1300);
        }, 250);
      }, 1200);
    }

    if (document.readyState !== "loading") {
      init();
    } else {
      document.addEventListener("DOMContentLoaded", () => {
        init();
      });
    }
  }, [ ]);

  // const [swiper, setSwiper] = useState(null);

  return (
    <ThemeProvider theme={theme}>
      <Loading state={loading}/>
      <div className="main">
        <Logo />

        <div className="content">
          <Swiper
            setLoading={setLoading}
          />
        </div>

        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default Main;
