import React, { useEffect } from 'react';
import { EffectCube, Navigation } from 'swiper';
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';

import Form from './Form';
import Slogan from './Slogan';

import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/navigation';
import './Swiper.css';

function Swiper({ setLoading }) {
    useEffect(() => {
        const timeout = setTimeout(() => {
            const slides = document.querySelectorAll('.swiper.swiper-cube .swiper-slide');
            // const bodyStyle = getComputedStyle(document.body);
            // !!bodyStyle.backgroundColor ? bodyStyle.backgroundColor : false;
            const backgroundColor = false; 

            let height = 0;
            slides.forEach((slide) => {
                if(height < slide.offsetHeight) {
                    height = slide.offsetHeight;
                }
            });

            // console.log(document.body.style);

            slides.forEach((slide) => {
                if(!!backgroundColor) {
                    slide.style.backgroundColor = backgroundColor;
                }

                slide.style.height = (height) + "px";
            });
        }, 500);

        return () => {
            clearTimeout(timeout);
        }
    }, []);

    return (
        <SwiperReact
            modules={ [ EffectCube, Navigation ] }
            effect="cube"
            cubeEffect={ {
                shadow: true,
                slideShadows: false,
                shadowOffset: 0,
                shadowScale: 0.70
            } }
            speed={800}
            // navigation
            // spaceBetween={50}
            slidesPerView={1}
            centeredSlides={true}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => { setSwiper(swiper) }}
        >
            <SwiperSlide>
                <Slogan />
            </SwiperSlide>
            <SwiperSlide>
                <Form
                    beforeSubmit={() => setLoading(true)}
                    afterSubmit={() => setLoading(false)}
                />
            </SwiperSlide>
        </SwiperReact>
    )
}

export default Swiper;