import React from 'react';
import { Box, IconButton, Link, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Privacy from './Privacy';
import Cookie from './Cookie';

function Footer() {

    const [modalComponent, setModalOpen] = React.useState(false);

    const handleModalOpen = (component) => {
        setModalOpen(component);
    }

    const handleModalClose = () => {
        setModalOpen(false);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        maxWidth: '100%',
        bgcolor: 'background.paper',
        border: 'none',
        // boxShadow: 24,
        padding: '25px',
        maxHeight: 'calc(100% - 150px)',
        overflow: 'auto'
    };

    return (
        <>
            <div className="Footer">
                { new Date().getFullYear() } ©
                &nbsp;
                <Link href="#" onClick={() => handleModalOpen(<Cookie />)}>cookie policy</Link>
                &nbsp;-&nbsp;
                <Link href="#" onClick={() => handleModalOpen(<Privacy />)}>privacy policy</Link>
            </div>
            <Modal
            open={!!modalComponent}
            onClose={handleModalClose}
            >
            <>
                <IconButton color='primary' onClick={handleModalClose} aria-label="close" sx={{ position: 'fixed', top: 15, right: 15}}>
                    <CloseIcon fontSize='large' />
                </IconButton>
                <Box sx={style}>
                    {modalComponent}
                </Box>
            </>
            </Modal>
        </>
        
    )
}

export default Footer;