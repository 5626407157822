import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './Main';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-20ZTFQCLS9', {
  debug: true,
  gaOptions: { cookieDomain: 'auto' }
});
ReactGA.send('pageview');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <Main />
  // </React.StrictMode>
  <Main />
);
