import React from 'react';
import { CircularProgress } from '@mui/material';

import './Loading.css';

function Loading(props) {

    return (
        <div className={ "Loading" + (props.state ? " enabled" : " disabled" ) }>
            <CircularProgress color="primary" />
        </div>
    );
}

export default Loading;